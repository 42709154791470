import React, { Component } from 'react';
import Typed from 'react-typed';

class TypedComponent extends Component {
    render() {
        const TypedStyle = {
            color: "#ffffff"
        };
        return (
            <div>
                Your Trusted source for all things<br></br><Typed style={TypedStyle}
                    strings={['Tech', 'Microsoft', 'Software', 'Cloud Services', 'Licensing']}
                    typeSpeed={80}
                    backSpeed={50}
                    backDelay={1000}
                    loop
                />
            </div>
        );
    }
}

export default TypedComponent;